import $ from "jquery";

$(function () {
  $(".click-to-show").each(function () {
    const label =
      ($(this).data("click-to-show-label") as string) || "Show more";

    $(this).wrapInner('<div class="click-to-show-inner"></div>');
    $(this).find(".click-to-show-inner").hide();
    $(this).prepend(
      '<a href="#" class="click-to-show-link">' + label + "&hellip;</a>"
    );

    $(this)
      .find(".click-to-show-link")
      .click(() => {
        $(this).find(".click-to-show-link").hide();
        $(this).find(".click-to-show-inner").slideDown();
      });
  });
});
